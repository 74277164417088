<template>
  <sheet-filters
    v-model="obFilterData"
    @apply="applyFilters"
    @reset="resetFilters"
    :model-name="modelClassName"
  >
    <template #default="{ apply }">
      <v-row>
        <v-col>
          <search-field v-model="obFilterData.search" @save="apply" clearable />
        </v-col>
      </v-row>
    </template>
  </sheet-filters>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { AppModule } from "@/store/app";
import CfecodesMixin from "../mixins/CfecodesMixin";

import SheetFilters from "@/components/form/SheetFilters.vue";
import SearchField from "@/components/form/fields/Search.vue";

@Component({
  components: { SheetFilters, SearchField },
})
export default class CfeCodeFilters extends Mixins(CfecodesMixin) {
  obFilterData: Record<string, any> = {};

  onMounted() {
    this.obFilterData = this.$_.get(AppModule.filters, this.modelClassName, {});
  }

  applyFilters() {
    this.$emit("apply", this.obFilterData);
  }

  resetFilters() {
    this.$emit("input", {});
    this.$emit("reset");

    this.applyFilters();
  }
}
</script>
